import React, {useRef, useState, useEffect} from 'react';
import {useSnackbar} from 'notistack';
import {useNavigate, useParams} from 'react-router-dom';
import { AxiosError, AxiosResponse } from 'axios';
import { Stack, Tooltip as MUITooltip, Typography, TextField, Button, Switch, FormGroup, FormControlLabel, Box, Dialog, DialogContent, FormControl, InputLabel, Select, SelectChangeEvent, MenuItem } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import SaveAltOutlinedIcon from '@mui/icons-material/SaveAltOutlined';
import ViewInArRoundedIcon from '@mui/icons-material/ViewInArRounded';
import CompareOutlinedIcon from '@mui/icons-material/CompareOutlined';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import * as _ from 'lodash';

import { Backdrop, CircularProgress } from '@mui/material';

import CircularSpinner from 'common/CircularSpinner';
import NavigationBar from 'common/NavigationBar';
import { RotationDirection, Page } from 'constants/enums';
import {get, getBinary, post, postBinary} from 'utils/api';
import { GET_API_URLS, POST_API_URLS } from 'constants/apiUrls';
import Sidebar, { MenuButton } from 'common/Sidebar';
import STLDisplay from 'components/Results/STLDisplay';
import WithAuthenticate from 'common/hoc/WithAuthenticate';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
import { Scatter } from 'react-chartjs-2';
import ResultFiles from './ResultFiles';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import OutlinedInput from 'common/OutlinedInput';
import { getEnumKeys, getExperimentTitle } from 'utils/utils';
import { IHubAttachment } from 'components/HubAttachments/AddHubAttachment';


ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

export const Results = () => {
    const {enqueueSnackbar} = useSnackbar();
    const navigate = useNavigate();
    const params = useParams();
    const experimentId = parseInt(params['experimentId'] || '0');
    
    const defaultHubAttachment = {id:0, name: 'None', topRightCorner: {x:0,y:0,z:0}};

    const [experiment, setExperiment] = useState(null);
    const [experimentResult, setExperimentResult] = useState({
        efficiency: '0', shaftPower:'0', finalChord:'0', finalRPM:'0', maxMachValue:'0', 
        r_over_r_n: [], re_n: [], drag_n: [], cl_iter: [], thrust_n: [], beta_output: []
    });
    const [localReynoldsNumberBasedResult, setLocalReynoldsNumberBasedResult] = useState({
        efficiency: '0', shaftPower:'0', maxMachValue:'0', re_n: [], drag_n: [], cl_iter: [], 
        thrust_n: [], beta_output: []
    });
    const [isLoadingExperiment, setIsLoadingExperiment] = useState<boolean>(false);
    const [isLoadingExperimentReynolds, setIsLoadingExperimentReynolds] = useState<boolean>(false);
    const [isLoadingStl, setIsLoadingStl] = useState<boolean>(false);
    const [isLoadingExport, setIsLoadingExport] = useState<boolean>(false);

    const [hubAttachments, setHubAttachments] = useState<Array<IHubAttachment>>([defaultHubAttachment]);
    const [selectedHubAttachmentId, setSelectedHubAttachmentId] = useState<number>(0);
    const [selectedRotationDirection, setSelectedRotationDirection] = useState<string>(RotationDirection.CLOCKWISE);
    const [useWireframe, setUseWireframe] = useState<boolean>(false);
    const [stlFileURL, setSTLFileURL] = useState<string | null>(null);
    const [isFilesDialogOpen, setIsFilesDialogOpen] = useState<boolean>(false);

    const expireCallback = () => {
        navigate('/login');
    }

    useEffect(() => {
        setIsLoadingExperiment(true);
        get(GET_API_URLS.EXPERIMENT(experimentId), expireCallback).then((res:AxiosResponse) => {
            if (res.status === 200) {
                setExperiment(res.data.experiment);
                setExperimentResult({
                    efficiency: `${Number(_.get(res.data.experiment, 'result.efficiency', '0')).toFixed(2)}`,
                    shaftPower: `${Number(_.get(res.data.experiment, 'result.other.power_shaft', '0')).toFixed(2)}`,
                    finalChord: `${Number(_.get(res.data.experiment, 'result.chord', '0')).toFixed(3)}`,
                    finalRPM: `${Number(_.get(res.data.experiment, 'result.rpm', '0')).toFixed(2)}`,
                    maxMachValue: `${Number(_.get(res.data.experiment, 'result.other.max_mach', '0')).toFixed(3)}`,
                    r_over_r_n: _.get(res.data.experiment, 'result.other.r_over_R_n', []),
                    re_n:  _.get(res.data.experiment, 'result.other.re_n', []),
                    drag_n:  _.get(res.data.experiment, 'result.other.drag_n', []),
                    cl_iter:  _.get(res.data.experiment, 'result.other.cl_iter', []),
                    thrust_n:  _.get(res.data.experiment, 'result.other.thrust_n', []),
                    beta_output:  _.get(res.data.experiment, 'result.other.beta_output', []),
                });

                if (_.get(res.data.experiment, 'result')) {
                    getLocalReynoldsNumberBasedResult();
                    getBladeSTLFile();
                } else {
                    enqueueSnackbar('The experiment does not have a result', {variant: 'warning'});
                }
            } else {
                enqueueSnackbar("Error while loading the experiment's result", {variant: 'error'});
            }
        }).catch((res:AxiosError) => {
            enqueueSnackbar("Error while loading the experiment's result", {variant: 'error'});
        }).finally(() => {
            setIsLoadingExperiment(false);
        })

        loadHubAttachments();

    }, []);

    const getLocalReynoldsNumberBasedResult = () => {
        setIsLoadingExperimentReynolds(true);
        get(GET_API_URLS.EXPERIMENT_RESULT_USING_LOCAL_REYNOLDS_NUMBER(experimentId), expireCallback).then((res:AxiosResponse) => {
            if (res.status === 200) {
                const result = res.data.local_reynolds_number_based_result;
                setLocalReynoldsNumberBasedResult({
                    efficiency: `${Number(_.get(result, 'efficiency', '0')).toFixed(2)}`,
                    shaftPower: `${Number(_.get(result, 'power_shaft', '0')).toFixed(2)}`,
                    maxMachValue: `${Number(_.get(result, 'max_mach', '0')).toFixed(3)}`,
                    re_n:  _.get(result, 're_n', []),
                    drag_n:  _.get(result, 'drag_n', []),
                    cl_iter:  _.get(result, 'cl_iter', []),
                    thrust_n:  _.get(result, 'thrust_n', []),
                    beta_output:  _.get(result, 'beta_output', []),
                })
            } else {
                enqueueSnackbar("Error while loading the experiment's result with local reynolds number calculation", {variant: 'error'});
            }
        }).catch((res:AxiosError) => {
            enqueueSnackbar("Error while loading the experiment's result with local reynolds number calculation", {variant: 'error'});
        }).finally(() => {
            setIsLoadingExperimentReynolds(false);
        })
    }

    useEffect(() => {
        if (_.get(experiment, 'result')) {
            getBladeSTLFile();
        }
    }, [selectedHubAttachmentId, selectedRotationDirection])

    const loadHubAttachments = () => {
        get(GET_API_URLS.HUB_ATTACHMENTS(), expireCallback).then((res:AxiosResponse) => {
            if (res.status === 200) {
                const _hubAttachments = res.data['hub_attachments'].map((hub_attachment:any) => ({
                    id: hub_attachment.id,
                    name: hub_attachment.name,
                    topRightCorner: {
                        'x': hub_attachment.top_right_corner.x, 
                        'y': hub_attachment.top_right_corner.y, 
                        'z': hub_attachment.top_right_corner.z
                    },
                }));
                setHubAttachments([defaultHubAttachment].concat(_hubAttachments));
            } else {
                enqueueSnackbar('Failed to fetch hub attachments', {variant: 'error'});
            }
        }).catch((res: AxiosError) => {
            enqueueSnackbar('Failed to fetch hub attachments', {variant: 'error'});
        });
    }

    const getBladeSTLFile = () => {
        const data = {
            generate_loft: (selectedHubAttachmentId !== 0),
            hub_attachment_id: selectedHubAttachmentId,
            counter_clockwise_rotation: (selectedRotationDirection === RotationDirection.COUNTER_CLOCKWISE)
        };

        postBinary(POST_API_URLS.EXPERIMENT_BLADE_STL_EXPORT(experimentId), data, expireCallback).then((res: AxiosResponse) => {
            if (res.status === 200) {
                const blob = new Blob([res.data], { type: 'application/sla', endings: "native" });
                const url = window.URL.createObjectURL(blob);
                setSTLFileURL(url);
            } else {
                enqueueSnackbar('Error while loading the STL file.', { variant: 'error' });
            }
        }).catch((res: AxiosError) => {
            enqueueSnackbar('Error while loading the STL file.', { variant: 'error' });
        });
    }


    const exportSTLFile = () => {
        setIsLoadingStl(true);
        if (stlFileURL) {
            const a = document.createElement("a");
            document.body.appendChild(a);
            a.href = stlFileURL;
            a.download = `${getExperimentTitle(experiment)}.stl`;
            a.click();
        }  
        setIsLoadingStl(false);       
    };


    const exportExperimentResult = () => {
        setIsLoadingExport(true);
        getBinary(GET_API_URLS.EXPERIMENT_RESULT_EXPORT(experimentId), expireCallback).then((res: AxiosResponse) => {
            if(res.status === 200){
                const blob = new Blob([res.data], {type: 'text/csv', endings: "native"})
                const a = document.createElement("a");
                document.body.appendChild(a);

                let url = window.URL.createObjectURL(blob);
                a.href = url;
                a.download = `${getExperimentTitle(experiment)}_result.csv`;
                a.click();
                setTimeout(function() {
                    window.URL.revokeObjectURL(url);
                }, 1000);

                enqueueSnackbar("Downloaded the experiment result csv file successfully", {variant: 'success'});
            } else {
                enqueueSnackbar("Error while downloading the experiment result csv file", {variant: 'error'});
            }
        }).catch((err: AxiosError) => {
            enqueueSnackbar("Error while downloading the experiment result csv file", {variant: 'error'});
      }).finally(() => {
        setIsLoadingExport(false);
      });
    }

    const goToComparisonTablePage = () => {
        navigate(`/compare/results/${experimentId}`);
    }

    const goToExperimentAnalysis = () => {
        navigate(`/analysis/${experimentId}`, {
            state: {
                experiment_detail: experiment
            }
          });
    }

    const reynoldsNumberGraphData = {
        labels: experimentResult.r_over_r_n.map((x:number) => x.toFixed(3)),
        datasets: [{
            data: experimentResult.re_n.map((y:number) => y.toFixed(5)), 
            borderColor: '#2196f3',
            pointBackgroundColor: '#2196f3',
            showLine: true,
        }]
    };

    const thrustGraphData = {
        labels: experimentResult.r_over_r_n.map((x:number) => x.toFixed(3)),
        datasets: [{
            label: 'Fixed Reynolds Number Based',
            data: experimentResult.thrust_n.map((y:number) => (y/4.4482216).toFixed(5)), // converting Newton to pound force 
            borderColor: '#2196f3',
            pointBackgroundColor: '#2196f3',
            showLine: true,
        }, {
            label: 'Local Reynolds Number Based',
            data: localReynoldsNumberBasedResult.thrust_n.map((y:number) => (y/4.4482216).toFixed(5)), // converting Newton to pound force 
            borderColor: '#f44336',
            pointBackgroundColor: '#f44336',
            showLine: true,
        }]
    };

    const dragGraphData = {
        labels: experimentResult.r_over_r_n.map((x:number) => x.toFixed(3)),
        datasets: [{
            label: 'Fixed Reynolds Number Based',
            data: experimentResult.drag_n.map((y:number) => y.toFixed(5)), 
            borderColor: '#2196f3',
            pointBackgroundColor: '#2196f3',
            showLine: true,
        }, {
            label: 'Local Reynolds Number Based',
            data: localReynoldsNumberBasedResult.drag_n.map((y:number) => y.toFixed(5)), 
            borderColor: '#f44336',
            pointBackgroundColor: '#f44336',
            showLine: true,
        }]
    };

    const clGraphData = {
        labels: experimentResult.r_over_r_n.map((x:number) => x.toFixed(3)),
        datasets: [{
            label: 'Fixed Reynolds Number Based',
            data: experimentResult.cl_iter.map((y:number) => y.toFixed(5)), 
            borderColor: '#2196f3',
            pointBackgroundColor: '#2196f3',
            showLine: true,
        }, {
            label: 'Local Reynolds Number Based',
            data: localReynoldsNumberBasedResult.cl_iter.map((y:number) => y.toFixed(5)), 
            borderColor: '#f44336',
            pointBackgroundColor: '#f44336',
            showLine: true,
        }]
    };

    const betaGraphData = {
        labels: experimentResult.r_over_r_n.map((x:number) => x.toFixed(3)),
        datasets: [{
            data: experimentResult.beta_output.map((y:number) => y.toFixed(5)), 
            borderColor: '#2196f3',
            pointBackgroundColor: '#2196f3',
            showLine: true,
        }]
    };

    const getGraphOptions = (title: string, xLabel: string, yLabel: string, displayLegend: boolean) => ({
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
            tooltip: {
                callbacks: {
                    label: (context:any) => {
                        return `${Number(context.label).toFixed(3)}, ${Number(context.raw).toFixed(5)}`;
                    }
                }
            },
            legend: {
              display: displayLegend,
            },
            title: {
              display: true,
              text: title,
            },
          },
          scales: {
              x: {
                  display: true,
                  title: {
                      display: true,
                      text: xLabel,
                  },
              },
              y: {
                  display: true,
                  title: {
                      display: true,
                      text: yLabel
                  },
                  ticks: {
                    display: true,
                    autoSkip: false,
                  }
              },
          }
    });


    const Menubar = () => {
        return (
            <Stack spacing={2} style={{marginTop:'30px', marginBottom: '20px'}}>
                <MenuButton 
                    Icon={CompareOutlinedIcon}
                    iconColor='black'
                    label='Compare Results'
                    onClick={goToComparisonTablePage}
                    disabled={false}
                />
                <MenuButton 
                    Icon={QueryStatsIcon}
                    iconColor='black'
                    label={'Off-Condition Analysis'}
                    onClick={goToExperimentAnalysis}
                    disabled={false}
                />
                <MenuButton 
                    Icon={SaveAltOutlinedIcon}
                    iconColor='black'
                    label='Export Result'
                    onClick={exportExperimentResult}
                    disabled={false}
                />
                <MenuButton 
                    Icon={ViewInArRoundedIcon}
                    iconColor='black'
                    label='Export STL'
                    onClick={exportSTLFile}
                    disabled={false}
                />
            </Stack>
        );
    };

    const theme = createTheme({
        components: {
          MuiSwitch: {
            styleOverrides: {
              switchBase: {
                // Controls default (unchecked) color for the thumb
                color: "#ccc"
              },
              colorPrimary: {
                "&.Mui-checked": {
                  // Controls checked color for the thumb
                  color: "#e0e0e0"
                }
              },
              track: {
                // Controls default (unchecked) color for the track
                opacity: 0.2,
                backgroundColor: "#e0e0e0",
                ".Mui-checked.Mui-checked + &": {
                  // Controls checked color for the track
                  opacity: 0.7,
                  backgroundColor: "#e0e0e0"
                }
              }
            }
          }
        }
    });

    const ResultsFieldsLabel = (experimentResultVar:string|number,localReynoldsNumberBasedResultVar:string|number)=>{
        if(Number(localReynoldsNumberBasedResultVar) && Number(localReynoldsNumberBasedResultVar) != 0){
            return `${experimentResultVar} (${localReynoldsNumberBasedResultVar} - Local Reynolds Number Based)`
        } else {
            return `${experimentResultVar}`
        }
    }

    const experimentTitle = getExperimentTitle(experiment);

    return (
        <>
            {isLoadingExport && <CircularSpinner />}
            {isLoadingStl && <CircularSpinner document={document.getElementById('stlDisplay')}/>}
            {isLoadingExperiment || isLoadingExperimentReynolds&& <CircularSpinner document={document.getElementById('results')}/>}
            {isLoadingExperiment || isLoadingExperimentReynolds && <CircularSpinner document={document.getElementById('graphs')}/>}
            <NavigationBar showMenu={true}/>
            <Stack direction='row' alignItems='stretch' justifyContent='flex-start'>
                <div style={{width:'50px'}}>
                    <Sidebar Menubar={Menubar} ActivePage={Page.RESULTS} />
                </div>
                <Stack spacing={3} style={{paddingLeft: '30px', marginBottom: '30px'}}>
                    <Typography 
                        style={{textAlign: 'left', fontSize:'24px', color: '#000000DE', paddingTop:'10px'}}
                    >
                        Optimization Results
                    </Typography>
                    <Stack spacing={2} direction='row'>
                        <fieldset id='results' style={{ width:'390px', border: '1px solid #6D77B0', borderRadius: '12px', padding: '5px 12px 14px 14px'}}>
                            <legend style={{marginLeft: '17px'}}>{experimentTitle}</legend>
                            <Stack spacing={2}>
                                <OutlinedInput
                                    label='Efficiency'
                                    value={ResultsFieldsLabel(experimentResult.efficiency,localReynoldsNumberBasedResult.efficiency)}
                                    onValueChange={(newEfficiency) => {}}
                                    readOnly={true}
                                    style={{width:'calc(100% - 34px)', height: '48px'}}
                                    tooltip='Propulsive efficiency of the propeller η = Thrust Power / Shaft Power'
                                />
                                <OutlinedInput
                                    label='Shaft Power (W)'
                                    value={ResultsFieldsLabel(experimentResult.shaftPower,localReynoldsNumberBasedResult.shaftPower)}
                                    onValueChange={(newShaftPower) => {}}
                                    readOnly={true}
                                    style={{width:'calc(100% - 34px)', height: '48px'}}
                                    tooltip='Estimated power needed to spin the shaft at the RPM under given loadings'
                                />
                                <OutlinedInput
                                    label='Thrust (lbf)'
                                    value={ResultsFieldsLabel((experimentResult.thrust_n.reduce((sum,currentValue)=>sum+currentValue,0)/experimentResult.thrust_n.length).toFixed(3),(localReynoldsNumberBasedResult.thrust_n.reduce((sum,currentValue)=>sum+currentValue,0)/localReynoldsNumberBasedResult.thrust_n.length).toFixed(3))}
                                    onValueChange={(newThrust) => {}}
                                    readOnly={true}
                                    style={{width:'calc(100% - 34px)', height: '48px'}}
                                    tooltip='Force generated by the blade'
                                />
                                <OutlinedInput
                                    label='Final Chord (in)'
                                    value={experimentResult.finalChord}
                                    onValueChange={(newFinalChord) => {}}
                                    readOnly={true}
                                    style={{width:'calc(100% - 34px)', height: '48px'}}
                                    tooltip={`Chord after optimization`}
                                />
                                <OutlinedInput
                                    label='Final RPM'
                                    value={experimentResult.finalRPM}
                                    onValueChange={(newFinalRPM) => {}}
                                    readOnly={true}
                                    style={{width:'calc(100% - 34px)', height: '48px'}}
                                    tooltip={`RPM after optimization`}
                                />
                                <OutlinedInput
                                    label='Max Mach Value'
                                    value={ResultsFieldsLabel(experimentResult.maxMachValue,localReynoldsNumberBasedResult.maxMachValue)}
                                    onValueChange={(newMaxMachValue) => {}}
                                    readOnly={true}
                                    style={{width:'calc(100% - 34px)', height: '48px'}}
                                    tooltip='Maximum Mach value of airflow at any point on the blade. Mach > .7 increases error in calculations'
                                />
                                <Button
                                    variant='contained'
                                    onClick={() => setIsFilesDialogOpen(true)}
                                    style={{marginTop: '38px'}}
                                >
                                    Result Files
                                </Button>
                            </Stack>
                        </fieldset>
                        <Stack id='stlDisplay' style={{border: '1px solid #6D77B0', borderRadius:'12px'}}>
                            {stlFileURL && 
                                <STLDisplay 
                                    file={stlFileURL} 
                                    useWireFrame={useWireframe} 
                                    width='690px'
                                    height='480px' //430px
                                />
                            }
                            <Stack position={"absolute"} marginLeft={'20px'} marginTop={'10px'}>
                                <ThemeProvider theme={theme}>
                                    <FormGroup>
                                        <FormControl sx={{m:1, width: '22ch'}} variant="outlined" style={{marginTop:'20px'}}>
                                            <InputLabel
                                                id='hub-attachment-label'
                                                style={{fontSize:'17px'}}
                                            >
                                                Hub Attachment
                                            </InputLabel>
                                            <Select
                                                id='hub-attachment'
                                                labelId='hub-attachment-label'
                                                label='Hub Attachment'
                                                value={selectedHubAttachmentId}
                                                onChange={(e) => {
                                                    setSelectedHubAttachmentId(e.target.value as number);
                                                }}
                                                size='small'
                                                style={{height:'43px'}}
                                            >
                                                {hubAttachments.map((hubAttachment, key) => {
                                                    return <MenuItem key={key} value={hubAttachment.id}>{hubAttachment.name}</MenuItem>
                                                })}
                                            </Select>
                                        </FormControl>
                                        <FormControl sx={{m:1, width: '22ch'}} variant="outlined" style={{marginTop:'20px'}}>
                                            <InputLabel
                                                id='rotation-direction-label'
                                                style={{fontSize:'17px'}}
                                            >
                                                Rotation Direction
                                            </InputLabel>
                                            <Select
                                                id='rotation-direction'
                                                labelId='rotation-direction-label'
                                                label='Rotation Direction'
                                                value={selectedRotationDirection}
                                                onChange={(e) => {
                                                    setSelectedRotationDirection(e.target.value as string);
                                                }}
                                                size='small'
                                                style={{height:'43px'}}
                                            >
                                                <MenuItem value={RotationDirection.CLOCKWISE}>{RotationDirection.CLOCKWISE}</MenuItem>
                                                <MenuItem value={RotationDirection.COUNTER_CLOCKWISE}>{RotationDirection.COUNTER_CLOCKWISE}</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <FormControlLabel 
                                            label={<Box component="div" fontSize={12}>Wireframe</Box>}
                                            color={'#212121'}
                                            control={<Switch checked={useWireframe} onChange={e=>setUseWireframe(e.target.checked)}/>}
                                            style={{marginLeft:'0px'}}
                                        />
                                    </FormGroup>
                                </ThemeProvider>
                            </Stack>
                        </Stack>
                    </Stack>
                    <div id='graphs'>
                        <Stack spacing={2} direction='row'>
                            <fieldset className='input-grouping' style={{width: '550px', height: '300px' }}>
                                <Scatter 
                                    options={getGraphOptions('Reynolds # at each section along the blade', 'r/R', 'Reynolds Reference', false)} 
                                    data={reynoldsNumberGraphData}
                                />
                            </fieldset>
                            <fieldset className='input-grouping' style={{width: '550px', height: '300px' }}>
                                <Scatter 
                                    options={getGraphOptions('Thrust produced at each section along the blade', 'r/R', 'Thrust (lbf)', true)} 
                                    data={thrustGraphData}
                                />
                            </fieldset>
                        </Stack>
                        <Stack spacing={2} direction='row'>
                            <fieldset className='input-grouping' style={{width: '550px', height: '300px' }}>
                                <Scatter 
                                    options={getGraphOptions('Drag produced at each section along the blade', 'r/R', 'Drag', true)} 
                                    data={dragGraphData}
                                />
                            </fieldset>
                            <fieldset className='input-grouping' style={{width: '550px', height: '300px' }}>
                                <Scatter 
                                    options={getGraphOptions('Coefficient of lift at each section along the blade', 'r/R', 'CL', true)} 
                                    data={clGraphData}
                                />
                            </fieldset>
                        </Stack>
                        <fieldset className='input-grouping' style={{width: '550px', height: '300px' }}>
                            <Scatter 
                                options={getGraphOptions('Beta pitch angle at each section along the blade (Not angle of attack)', 'r/R', 'Beta (Degree)', false)} 
                                data={betaGraphData}
                            />
                        </fieldset>
                    </div>   
                </Stack>
            </Stack>
            <Dialog
                open={isFilesDialogOpen}
                onClose={() => setIsFilesDialogOpen(false)}
                PaperProps={{
                    style: {
                      backgroundColor: 'transparent',
                      boxShadow: 'none',
                      maxWidth:'700px'
                    }
                  }}
            >
                <DialogContent style={{backgroundColor: 'transparent', scrollbarWidth: 'none'}}>
                  <ResultFiles experimentTitle={experimentTitle}/>
                </DialogContent>
            </Dialog>
        </>
    );
}

export default WithAuthenticate(Results);