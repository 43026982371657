import React, {useState, useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import { AxiosError, AxiosResponse } from 'axios';
import { Stack, Tooltip as MUITooltip, Typography, TextField, Divider} from '@mui/material';
import SaveAltOutlinedIcon from '@mui/icons-material/SaveAltOutlined';
import ViewInArRoundedIcon from '@mui/icons-material/ViewInArRounded';
import CompareOutlinedIcon from '@mui/icons-material/CompareOutlined';
import * as _ from 'lodash';
import CircularSpinner from 'common/CircularSpinner';
import NavigationBar from 'common/NavigationBar';
import { Page } from 'constants/enums';
import Sidebar, { MenuButton } from 'common/Sidebar';
import STLDisplay from 'components/Results/STLDisplay';
import WithAuthenticate from 'common/hoc/WithAuthenticate';
import { useLocation } from "react-router-dom";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
import { Scatter } from 'react-chartjs-2';
import { ResultFields } from 'components/Results/ResultFields';
import { getExperimentTitle } from 'utils/utils';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

export const DetailResultsComparison = () => {
    const navigate = useNavigate();

    const experiment_details = useLocation();
    const experiment_details_results = _.get(experiment_details,'state.experiment_details',[]);

    const goToComparisonTablePage = () => {
        navigate('/compare/results');
    }

    const colorList = ['#2196f3', '#f44336', '#4caf50'];

    useEffect(() =>{
        if(Object.is(experiment_details.state, null)){
            navigate('/compare/results');
        }
    },[]);
    
    const reynoldsNumberGraphData = {
        datasets: experiment_details_results.map((experiment:any, index: number) => ({
            label: getExperimentTitle(experiment),
            data: _.get(experiment, 'result.other.r_over_R_n', []).map((r_over_R:number,idx:number) => ({
                    x:r_over_R.toFixed(3), 
                    y:(_.get(experiment, 'result.other.re_n', [])[idx]).toFixed(5)
                })
            ),
            borderColor: colorList[index%3],
            pointBackgroundColor: colorList[index%3],
            showLine: true,
        }))
    };

    const thrustGraphData = {
        datasets: experiment_details_results.map((experiment:any, index: number) => ({
            label: getExperimentTitle(experiment),
            data: _.get(experiment, 'result.other.r_over_R_n', []).map((r_over_R:number,idx:number) => ({
                    x:r_over_R.toFixed(3), 
                    y:(_.get(experiment, 'result.other.thrust_n', [])[idx]/4.4482216).toFixed(5) // converting Newton to pound force
                })
            ),
            borderColor: colorList[index%3],
            pointBackgroundColor: colorList[index%3],
            showLine: true,
        }))
    };

    const dragGraphData = {
        datasets: experiment_details_results.map((experiment:any, index: number) => ({
            label: getExperimentTitle(experiment),
            data: _.get(experiment, 'result.other.r_over_R_n', []).map((r_over_R:number,idx:number) => ({
                    x:r_over_R.toFixed(3), 
                    y:(_.get(experiment, 'result.other.drag_n', [])[idx]).toFixed(5)
                })
            ),
            borderColor: colorList[index%3],
            pointBackgroundColor: colorList[index%3],
            showLine: true,
        }))
    };

    const clGraphData = {
        datasets: experiment_details_results.map((experiment:any, index: number) => ({
            label: getExperimentTitle(experiment),
            data: _.get(experiment, 'result.other.r_over_R_n', []).map((r_over_R:number,idx:number) => ({
                    x:r_over_R.toFixed(3), 
                    y:(_.get(experiment, 'result.other.cl_iter', [])[idx]).toFixed(5)
                })
            ),
            borderColor: colorList[index%3],
            pointBackgroundColor: colorList[index%3],
            showLine: true,
        }))
    };

    const betaGraphData = {
        datasets: experiment_details_results.map((experiment:any, index: number) => ({
            label: getExperimentTitle(experiment),
            data: _.get(experiment, 'result.other.r_over_R_n', []).map((r_over_R:number,idx:number) => ({
                    x:r_over_R.toFixed(3), 
                    y:(_.get(experiment, 'result.other.beta_output', [])[idx]).toFixed(5)
                })
            ),
            borderColor: colorList[index%3],
            pointBackgroundColor: colorList[index%3],
            showLine: true,
        }))
    };

    const rpmShaftPowerGraphData = {
        datasets: experiment_details_results.map((experiment:any, index: number) => ({
            label: getExperimentTitle(experiment),
            data: [{
                x: Number(_.get(experiment, 'result.other.power_shaft', '0')).toFixed(2),
                y: Number(_.get(experiment, 'result.rpm', '0')).toFixed(5),
            }],
            borderColor: colorList[index%3],
            pointBackgroundColor: colorList[index%3],
        }))
    };
    
    const getGraphOptions = (title: string, xLabel: string, yLabel: string) => ({
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
            tooltip: {
                callbacks: {
                    label: (context:any) => {
                        return `${Number(context.label).toFixed(3)}, ${Number(context.raw).toFixed(5)}`;
                    }
                }
            },
            legend: {
              display: true,
            },
            title: {
              display: true,
              text: title,
            },
          },
          scales: {
              x: {
                  display: true,
                  title: {
                      display: true,
                      text: xLabel,
                  },
              },
              y: {
                  display: true,
                  title: {
                      display: true,
                      text: yLabel
                  },
                  ticks: {
                    display: true,
                    autoSkip: false,
                  }
              },
          }
      });

    const Menubar = () => {
        return (
            <Stack spacing={2} style={{marginTop:'30px', marginBottom: '20px'}}>
                <MenuButton 
                    Icon={CompareOutlinedIcon}
                    iconColor='black'
                    label='Compare'
                    onClick={goToComparisonTablePage}
                    disabled={false}
                />
            </Stack>
        );
    };

    return (
        <>
            <NavigationBar showMenu={true}/>
            <Stack direction='row' spacing={3}>
            
                <div style={{width:'50px'}}>
                    <Sidebar Menubar={Menubar} ActivePage={Page.RESULTS}/>
                </div>
                
            
                <Stack spacing={3} style={{marginBottom:'30px'}}>
                    <Typography 
                        style={{textAlign: 'left', fontSize:'24px', color: '#000000DE', paddingTop:'10px'}}
                    >
                        Comparison Results Detail
                    </Typography>
                
                    <Stack 
                        spacing={2} 
                        divider={<Divider orientation="vertical" flexItem />} 
                        direction='row'
                        justifyContent='center'
                    >
                        {experiment_details_results.map((experiment: any, key: number) => (
                            <ResultFields 
                                key={key}
                                title={getExperimentTitle(experiment)}
                                efficiency={Number(_.get(experiment, 'result.efficiency', '0'))}
                                shaftPower={Number(_.get(experiment, 'result.other.power_shaft', '0'))}
                                thrust={Number(_.get(experiment, 'result.other.thrust_lbf', '0'))}
                                finalChord={Number(_.get(experiment, 'result.chord', '0'))}
                                finalRPM={Number(_.get(experiment, 'result.rpm', '0'))}
                                maxMachValue={Number(_.get(experiment, 'result.other.max_mach', '0'))}
                            />
                        ))}
                    </Stack>
                
            
                    <Stack spacing={2}>
                        <Stack direction='row' spacing={2}>
                            <fieldset className='input-grouping' style={{width: '550px', height: '300px'}}>
                                <Scatter 
                                    options={getGraphOptions('Reynolds # at each section along the blade', 'r/R', 'Reynolds Reference')} 
                                    data={reynoldsNumberGraphData}
                                />
                            </fieldset>
                            <fieldset className='input-grouping' style={{width: '550px', height: '300px'}}>
                                <Scatter 
                                    options={getGraphOptions('Thrust produced at each section along the blade', 'r/R', 'Thrust (lbf)')} 
                                    data={thrustGraphData}
                                />
                            </fieldset>
                        </Stack>
                        
                        <Stack direction='row' spacing={2}>
                            <fieldset className='input-grouping' style={{width: '550px', height: '300px'}}>
                                <Scatter 
                                    options={getGraphOptions('Drag produced at each section along the blade', 'r/R', 'Drag')} 
                                    data={dragGraphData}
                                />
                            </fieldset>
                            <fieldset className='input-grouping' style={{width: '550px', height: '300px'}}>
                                <Scatter 
                                    options={getGraphOptions('Coefficient of lift at each section along the blade', 'r/R', 'CL')} 
                                    data={clGraphData}
                                />
                            </fieldset>
                        </Stack>

                        <Stack direction='row' spacing={2}>
                            <fieldset className='input-grouping' style={{width: '550px', height: '300px'}}>
                                <Scatter 
                                    options={getGraphOptions('Beta pitch angle at each section along the blade (Not angle of attack)', 'r/R', 'Beta (Degree)')} 
                                    data={betaGraphData}
                                />
                            </fieldset>
                            <fieldset className='input-grouping' style={{width: '550px', height: '300px'}}>
                                <Scatter  
                                    options={getGraphOptions('Comparison between propeller designs for Shaft Power vs RPM', 'Shaft Power', 'RPM')}
                                    data={rpmShaftPowerGraphData}
                                />
                            </fieldset>    
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
        </>
    );
}

export default WithAuthenticate(DetailResultsComparison);