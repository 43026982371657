import React, {useEffect, useState} from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { Dialog, DialogContent, IconButton, Stack, Typography } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { DataGrid, GridCallbackDetails, GridColDef, GridRowParams, MuiEvent } from '@mui/x-data-grid';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import Save from '@mui/icons-material/DeleteForeverOutlined';
import * as _ from 'lodash';
import SaveAltOutlinedIcon from '@mui/icons-material/SaveAltOutlined';

import CircularSpinner from 'common/CircularSpinner';
import NavigationBar from "common/NavigationBar";
import {DELETE_API_URLS, GET_API_URLS} from 'constants/apiUrls';
import { del, get, getBinary, isLoggedIn } from 'utils/api';
import Sidebar, { MenuButton } from 'common/Sidebar';
import { Page } from 'constants/enums';
import { AxiosError, AxiosResponse } from 'axios';
import AddHubAttachment, { IHubAttachment } from './AddHubAttachment';
import ConfirmationDialog, { ConfirmationProps } from 'common/ConfirmationDialog';
import STLDisplay from 'components/Results/STLDisplay';
import { blobToDataURL } from 'utils/utils';
import WithAuthenticate from 'common/hoc/WithAuthenticate';


export const HubAttachments = () => {
    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();

    const [hubAttachments, setHubAttachments] = useState<Array<IHubAttachment>>([]);
    const [selectedHubAttachment, setSelectedHubAttachment] = useState<IHubAttachment | null>(null);
    const [selectedHubAttachmentFileURL, setSelectedHubAttachmentFileURL] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isRegistrationOpen, setIsRegistrationOpen] = useState<boolean>(false);
    const [confirmationProps, setConfirmationProps] = useState<ConfirmationProps | null>(null); 

    const [pageSize, setPageSize] = useState<number>(25);

    const expireCallback = () => {
        navigate('/login');
    }

    useEffect(() => {
        if (!isLoggedIn()) {
            navigate('/login');
            return;
        }

        loadHubAttachments();
    }, []);

    const loadHubAttachments = () => {
        setIsLoading(true);
        get(GET_API_URLS.HUB_ATTACHMENTS(), expireCallback).then((res:AxiosResponse) => {
            if (res.status === 200) {
                const _hubAttachments = res.data['hub_attachments'].map((hub_attachment:any) => ({
                    id: hub_attachment.id,
                    name: hub_attachment.name,
                    topRightCorner: {
                        'x': hub_attachment.top_right_corner.x, 
                        'y': hub_attachment.top_right_corner.y, 
                        'z': hub_attachment.top_right_corner.z
                    },
                }));
                setHubAttachments(_hubAttachments);
                setSelectedHubAttachmentFileURL('');
            } else {
                enqueueSnackbar('Failed to fetch hub attachments', {variant: 'error'});
            }
        }).catch((res: AxiosError) => {
            enqueueSnackbar('Failed to fetch hub attachments', {variant: 'error'});
        }).finally(() => {
            setIsLoading(false);
        })
    }

    const loadHubAttachmentFile = (hubAttachmentId: number) => {
        setIsLoading(true);
        getBinary(GET_API_URLS.HUB_ATTACHMENT_FILE(hubAttachmentId), expireCallback).then((res:AxiosResponse) => {

            if (res.status === 200) {
                const blob = new Blob([res.data], {type: 'application/sla', endings: 'native'});
                blobToDataURL(blob, setSelectedHubAttachmentFileURL);
            } else {
                enqueueSnackbar('Error while loading the hub attachment file', {variant: 'error'});
            }
        }).catch((res:AxiosError) => {
            enqueueSnackbar('Error while loading the hub attachment file', {variant: 'error'});
        }).finally(() => {
            setIsLoading(false);
        })
    }

    const deleteHubAttachment = (hubAttachmentId: number) => {
        setIsLoading(true);
        del(DELETE_API_URLS.HUB_ATTACHMENT_DELETE(hubAttachmentId), expireCallback).then((res:AxiosResponse) => {
            if (res.status === 200) {
                enqueueSnackbar('Deleted the hub attachment successfully.', {variant: 'success'});
            } else {
                enqueueSnackbar('Error while deleting the hub attachment.', {variant: 'error'});
            }
        }).catch((res:AxiosError) => {
            enqueueSnackbar('Error while deleting the hub attachment.', {variant: 'error'});
        }).finally(() => {
            setIsLoading(false);
            setConfirmationProps(null);
            loadHubAttachments();
        })
    };

    const downloadHubAttachmentFile = (hubAttachmentId: number, hubAttachmentName: string) => {
        setIsLoading(true);
        getBinary(GET_API_URLS.HUB_ATTACHMENT_FILE(hubAttachmentId)).then((res: AxiosResponse) => {

            const blob = new Blob([res.data], {type: 'application/sla', endings: 'native'});
            const url = window.URL.createObjectURL(blob);

            let a = document.createElement("a");
            document.body.appendChild(a);
            a.href = url;
            a.download = `${hubAttachmentName}.stl`;
            a.click();
             
            enqueueSnackbar('Downloaded the hub attachment successfully.', {variant: 'success'});
        }).catch((res:AxiosError) => {
            enqueueSnackbar('Error while downloading the hub attachment.', {variant: 'error'});
        }).finally(() => {
            setIsLoading(false);
        })
    }

    const columns: GridColDef[] = [
        {field: 'name', headerName: 'Hub Attachment', headerAlign: 'center', align:'center', width: 200, editable: false, type: 'string'},
        {field: 'topRighCorner', headerName: 'Top Right Corner', headerAlign: 'center', align:'center', width: 200, editable: false, type: 'string',
            renderCell: (params) => {
                const topRightCorner = params.row.topRightCorner;

                return (
                    <div>{`(${topRightCorner?.x}, ${topRightCorner?.y}, ${topRightCorner?.z})`}</div>
                );
            }
        },
        {field: 'edit', headerName: 'Edit', width:60, headerAlign: 'center', align: 'center', sortable: false, 
            renderCell: (params) => {
                return (
                    <IconButton onClick={() => setIsRegistrationOpen(true)}>
                        <EditOutlinedIcon />
                    </IconButton>
                );
            }
        },
        {field: 'download', headerAlign: 'center', align: 'center', headerName: 'Download', width: 90, sortable: false,
            renderCell: (params) => {
                return (
                    <IconButton 
                        onClick={() => {
                            downloadHubAttachmentFile(params.row.id, params.row.name)}
                        }
                    >
                        <SaveAltOutlinedIcon/>
                    </IconButton>
                );
            }
        },
        {field: 'delete', headerAlign: 'center', align: 'center', headerName: 'Delete', width: 60, sortable: false,
            renderCell: (params) => {
                const onDelete = (e:any) => {
                    e.stopPropagation();
                    
                    setConfirmationProps({
                        title: 'Delete Hub Attachment',
                        body: 'Do you want to delete the hub attachment?',
                        onCancel: () => setConfirmationProps(null),
                        onConfirm: () => deleteHubAttachment(parseInt(params.row.id))
                    });
                } 
                return (
                    <IconButton onClick={onDelete}>
                        <DeleteForeverOutlinedIcon />
                    </IconButton>
                );
            }

        }
    ];

    const onHubAtttachmentRegistrationComplete = () => {
        setIsRegistrationOpen(false);
        loadHubAttachments();
        setSelectedHubAttachment(null);
    }

    const Menubar = () => {
        return (
            <Stack spacing={2} style={{marginTop: '20px', marginBottom: '20px'}}>
                <MenuButton 
                    Icon={AddCircleOutlineIcon}
                    iconColor='black'
                    label='New'
                    onClick={() => {
                        setSelectedHubAttachment(null);
                        setIsRegistrationOpen(true);
                    }}
                    disabled={false}
                />
            </Stack>
        );
    }

    return (
        <>
            {isLoading && <CircularSpinner/>}
            <NavigationBar showMenu={true}/>
            <Stack direction='row' alignItems='stretch' justifyContent='flex-start' style={{height: '100%'}}>
                <div style={{width:'50px'}}>
                    <Sidebar Menubar={Menubar} ActivePage={Page.HUB_ATTACHMENTS}/>
                </div>
                <Stack spacing={3} style={{paddingLeft: '30px', width: '2060px'}}>
                    <Typography 
                        style={{textAlign: 'left', fontSize:'24px', color: '#000000DE', paddingTop:'10px'}}
                    >
                        Hub Attachments
                    </Typography>
                    <Stack 
                        direction='row' 
                        alignItems='stretch' 
                        justifyContent='flex-start'
                        spacing={10} 
                        style={{height: '100%'}}
                    >
                        <div style={{width:'940px', height:'800px'}}>
                            <DataGrid
                                columns={columns}
                                rows={hubAttachments}
                                onRowClick={(params: GridRowParams, event: MuiEvent, details: GridCallbackDetails) => {
                                    if (!_.isEqual(_.get(selectedHubAttachment, 'id', null), params.row.id)) {
                                        setSelectedHubAttachment({
                                            id: params.row.id,
                                            name: params.row.name,
                                            topRightCorner: params.row.topRightCorner,
                                        });
                                        loadHubAttachmentFile(params.row.id);
                                    }
                                }}
                                pageSize={pageSize}
                                onPageSizeChange={(newPageSize:number) => setPageSize(newPageSize)}
                                initialState={{
                                    sorting: {
                                        sortModel: [{field:'name', sort:'asc'}]
                                    }
                                }}
                            />
                        </div>
                        <div style={{width:'100%'}}>
                            {(selectedHubAttachmentFileURL) && 
                                <div style={{width:'1020px', border:'1px solid #6D77B0', borderRadius:'12px'}}>
                                    <STLDisplay 
                                        file={selectedHubAttachmentFileURL} 
                                        useWireFrame={false} 
                                        width='1000px'
                                        height='750px'
                                    />
                                </div>}
                        </div>
                    </Stack>
                </Stack>
            </Stack>
            <Dialog
                open={isRegistrationOpen}
                onClose={() => setIsRegistrationOpen(false)}
                PaperProps={{
                    style: {
                      backgroundColor: 'transparent',
                      boxShadow: 'none',
                      maxWidth: '1000px'
                    }
                }}
            >
                <DialogContent style={{backgroundColor:'transparent'}}>
                    <AddHubAttachment 
                        onRegistrationComplete={onHubAtttachmentRegistrationComplete} 
                        hubAttachment={selectedHubAttachment}
                        hubAttachmentFileURL={selectedHubAttachmentFileURL}
                    />
                </DialogContent>
            </Dialog>
            {confirmationProps && <ConfirmationDialog {...confirmationProps}/>}
        </>
    );
}

export default WithAuthenticate(HubAttachments);