import React, { useState, useEffect } from "react";
import { AxiosResponse, AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import { Typography, IconButton, FormControl, RadioGroup, FormControlLabel, Radio, Backdrop, SelectChangeEvent, Dialog, DialogTitle, DialogContent, DialogActions, Button, Stack, Theme } from '@mui/material';
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { useSnackbar } from "notistack";

import CircularSpinner from "common/CircularSpinner";
import ConfirmationDialog, { ConfirmationProps } from "common/ConfirmationDialog";
import { DELETE_API_URLS, GET_API_URLS, POST_API_URLS } from "constants/apiUrls";
import { get, post, del, isLoggedIn } from "utils/api";
import NavigationBar from "common/NavigationBar";
import Sidebar, {MenuButton} from "common/Sidebar";
import { Page } from "constants/enums";
import UserRegistration from "../UserRegistration";
import WithAuthenticate from "common/hoc/WithAuthenticate";

interface User {
  email: string,
  permission: string,
  date: string,
  id: number
}

interface Confirmation {
  user: User,
  newPermission: string,
  delete: boolean
}

export function Admin() {
  const navigate = useNavigate();
  const {enqueueSnackbar} = useSnackbar();
  
  const [allUsers,  setAllUsers]   = useState<Array<User>>([]);
  const [myPermission, setMyPermission] = useState<string>("INVALID");

  const [confirmation, setConfirmation] = useState<Confirmation | null>(null);
  const [dialogProps, setDialogProps] = useState<ConfirmationProps | null>(null);
  
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isRegistrationOpen, setIsRegistrationOpen] = useState<boolean>(false);

  const [pageSize, setPageSize] = useState<number>(25);

  const expireCallback = () => {
    navigate('/login');
}

  const loadUsers = () => {
    setIsLoading(true);
    get(GET_API_URLS.USERS(), expireCallback).then((res: AxiosResponse) => {
      if(res.data["permission"] !== "USER"){
        setAllUsers(res.data['users'])
        setMyPermission(res.data["permission"])
      }
    }).catch((res: AxiosError) => {
      enqueueSnackbar('Failed to fetch user data', {variant: 'error'});
    }).finally(() => {
      setIsLoading(false);
    });
  }

  const onCancel = () => setConfirmation(null);

  const onDelete = () => {
    if(confirmation) {
      setIsLoading(true);
      del(DELETE_API_URLS.USER_DELETE(Number(confirmation.user.id)), expireCallback).then( (res: AxiosResponse) => {
        if(res.status === 200){
          loadUsers();
          enqueueSnackbar('Successfully deleted the user', {variant: 'success'});
          setConfirmation(null);
        } else {
          enqueueSnackbar('Error while deleting the user', {variant: 'error'});
        }
      }).catch((err: AxiosError) => {
        enqueueSnackbar('Error while deleting the user', {variant: 'error'});
      }).finally(() => {
        setIsLoading(false);
      });
    }
  };

  const onPermissionChange = () => {
    if(confirmation) {
      
      setIsLoading(true);
      post(POST_API_URLS.USER_PERMISSION_CHANGE(confirmation.user.id),
      {
        "permission": confirmation.newPermission
      }, expireCallback).then( (res: AxiosResponse) => {
        if(res.status === 200){
          let users = [...allUsers];
          let uIndex = users.indexOf(confirmation.user);
          users[uIndex].permission = confirmation.newPermission;
          setAllUsers(users);
          enqueueSnackbar('Changed the user permission successfully', {variant: 'success'});
          setConfirmation(null);
        } else {
          enqueueSnackbar("Error while changing the user's permission", {variant: 'error'});
        }
      }).catch((err: AxiosError) => {
        enqueueSnackbar("Error while changing the user's permission", {variant: 'error'});
      }).finally(() => {
        setIsLoading(false);
      });
    }
  };

  useEffect( () => {
    if (!isLoggedIn()) {
      navigate('/login');
      return;
    }

    setIsLoading(true);
    get(GET_API_URLS.ADMIN_CHECK(), expireCallback).then((res:AxiosResponse) => {
      if (res.status === 200) {
        if (!res.data.is_admin) {
          navigate('/dashboard');
          enqueueSnackbar("You don't have admin permission.", {variant: 'error'});
        } else {
          loadUsers();
        }
      } else {
        enqueueSnackbar('Error while checking the permission.', {variant: 'error'});  
      }
    }).catch((err:AxiosError) => {
      enqueueSnackbar('Error while checking the permission.', {variant: 'error'});
    }).finally(() => {
        setIsLoading(false);
    });
  }, []);

  useEffect( () => {
    if(confirmation?.delete) {
      setDialogProps({
        title: "Delete User",
        body: ("Are you want to delete " + confirmation.user.email + "?"),
        onCancel: onCancel,
        onConfirm: onDelete
      });
    }
    else {
      setDialogProps({
        title: "Change Permission",
        body: ("Are you want to change the permission of user " + confirmation?.user.email + " to " + confirmation?.newPermission + "?"),
        onCancel: onCancel,
        onConfirm: onPermissionChange
      });
    }
  }, [confirmation]);

  const columns: GridColDef[] = [
    {field: 'email', headerName: 'Email', width:200, editable: false, type: 'string'},
    {field: 'date', headerName: 'Date Created', width:150, editable: false, type: 'date', renderCell: (params) => {
      const date = params.row.date;
      const formattedDate = new Date(date).toLocaleDateString('en-US');
      return (
        <div>{formattedDate}</div>
      );
    }},
    {
      field: 'permission',
      headerName: 'Permission Level',
      sortable: false,
      minWidth: 200,
      flex:1,
      renderCell: (params) => {
          return (
            (myPermission === 'SUPER_ADMIN') ? (
              <FormControl>
                <RadioGroup value={params.row.permission} onChange={e => setConfirmation({user: params.row, newPermission: e.target.value, delete: false})} row>
                  <FormControlLabel value="USER"  control={<Radio/>} label="User" />
                  <FormControlLabel value="ADMIN" control={<Radio/>} label="Admin" />
                </RadioGroup>
              </FormControl>
              ) : 
              (<Typography variant="body1">{params.row.permission === "USER" ? "User" : "Admin"}</Typography>)
          );
      }
    },

    {
      field: 'action', headerName: 'Delete', width: 90, sortable: false, renderCell: (params) => {
          return (
            <IconButton onClick={() => setConfirmation({user: params.row, newPermission: "", delete: true})}>
              <DeleteForeverOutlinedIcon/>
            </IconButton>
          );
      }

    }
  ];

  const onRegistrationComplete = () => {
    setIsRegistrationOpen(false);
    loadUsers();
  }

  const Menubar = () => {
    return (
      <Stack spacing={2} style={{marginTop:'30px', marginBottom:'20px'}}>
        <MenuButton
            Icon={PersonAddOutlinedIcon}
            iconColor='black'
            label='New'
            onClick={() => setIsRegistrationOpen(true)}
            disabled={false}
        />
      </Stack>
    );
  }

  return (
    <>
      {isLoading && <CircularSpinner/>}
      <NavigationBar showMenu={true}/>
      <Stack direction='row' alignItems='stretch' justifyContent='flex-start' style={{height:'100%'}}>
        <div style={{width:'50px'}}>
          <Sidebar Menubar={Menubar} ActivePage={Page.ADMIN}/>
        </div>
        <Stack spacing={3} style={{paddingLeft: '30px', width: '1156px'}}>
          <Typography 
              style={{textAlign: 'left', fontSize:'24px', color: '#000000DE', paddingTop:'10px'}}
          >
              Admin Panel
          </Typography>
          <DataGrid
              autoHeight
              columns={columns}
              rows={allUsers}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize:number) => setPageSize(newPageSize)}
          />
        </Stack>
      </Stack>
      <Dialog
        open={isRegistrationOpen}
        onClose={() => setIsRegistrationOpen(false)}
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none'
          }
        }}
      >
        <DialogContent style={{backgroundColor:'transparent'}}>
          <UserRegistration onRegistrationComplete={onRegistrationComplete}/>
        </DialogContent>
      </Dialog>
      {(confirmation && dialogProps) ? <ConfirmationDialog {...dialogProps} />:""}
    </>
  );
  
}

export default WithAuthenticate(Admin);
