import React, {useCallback, useEffect, useState} from 'react';
import axios from 'axios';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { Button, Dialog, DialogContent, FormControl, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, TextField, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { AxiosResponse, AxiosError } from 'axios';
import { Scatter } from 'react-chartjs-2';

import CircularSpinner from 'common/CircularSpinner';
import Sidebar, { MenuButton } from 'common/Sidebar';
import { IPoints } from 'components/Airfoils/AddAirfoil';
import { DELETE_API_URLS, GET_API_URLS, POST_API_URLS, PUT_API_URLS } from 'constants/apiUrls';
import { del, get, post, put, getBinary } from 'utils/api';
import Dropzone from 'common/Dropzone';
import NavigationBar from 'common/NavigationBar';
import { Page } from 'constants/enums';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ReynoldsNumberProperties, { IReynoldsNumberDetail } from 'components/Airfoils/ReynoldsNumberProperties';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import SaveAltOutlinedIcon from '@mui/icons-material/SaveAltOutlined';
import ConfirmationDialog, { ConfirmationProps } from 'common/ConfirmationDialog';
import WithAuthenticate from 'common/hoc/WithAuthenticate';

export const AirfoilDetail = () => {
    const {enqueueSnackbar} = useSnackbar(); 
    const navigate = useNavigate();
    const params = useParams();
    const airfoilId = parseInt(params['airfoilId'] || '0');

    const [name, setName] = useState<string>('');
    const [nameError, setNameError] = useState<string>('');
    const [originalPoints, setOriginalPoints] = useState<Array<IPoints>>([]);
    const [interpolatedPoints, setInterpolatedPoints] = useState<Array<IPoints>>([]);
    const [confirmationProps, setConfirmationProps] = useState<ConfirmationProps | null>(null);
    const [reynoldsNumbersProperties, setReynoldsNumbersProperties] = useState<Array<{id:number, reynoldsNumber: number}>>([]);
    const [selectedReynoldsNumber, setSelectedReynoldsNumber] = useState<IReynoldsNumberDetail | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isReynoldsNumberPropertiesOpen, setIsReynoldsNumberPropertiesOpen] = useState<boolean>(false);
    const [pageSize, setPageSize] = useState<number>(25);

    useEffect(()=> {
        setIsLoading(true);

        const requests = [
            get(GET_API_URLS.AIRFOIL(airfoilId), expireCallback),
            get(GET_API_URLS.ALL_REYNOLDS_NUMBERS_PROPERTIES(airfoilId), expireCallback)
        ];
        axios.all(requests).then(axios.spread(
            (airfoilDetailResponse: AxiosResponse, reynoldsNumbersPropertiesResponse: AxiosResponse) => {
                if (airfoilDetailResponse.status === 200) {
                    const airfoil = airfoilDetailResponse.data.airfoil;
                    setName(airfoil.name);
                    const points = airfoil.points.map((point:Array<[number, number]>) => ({
                        x: point[0],
                        y: point[1]
                    }));
                    const _interpolatedPoints = airfoil.interpolated_points.map((point:Array<[number, number]>) => ({
                        x: point[0],
                        y: point[1]
                    }));
                    setOriginalPoints(points);
                    setInterpolatedPoints(_interpolatedPoints);
                } else {
                    enqueueSnackbar('Failed to fetch airfoils', {variant: 'error'});
                }

                if (reynoldsNumbersPropertiesResponse.status === 200) {
                    const _reynoldsNumbersProperties = reynoldsNumbersPropertiesResponse.data.reynolds_numbers_properties; 
                    setReynoldsNumbersProperties(_reynoldsNumbersProperties.map((property:any) => ({
                        id: property.id,    
                        reynoldsNumber: property.reynolds_number
                    })));
                }
        })).catch((res: AxiosError) => {
            enqueueSnackbar('Failed to fetch airfoil and Reynolds number details', {variant: 'error'});
        }).finally(() => {
            setIsLoading(false);
        });

    }, []);

    const loadReynoldsNumbersProperties = () => {
        setIsLoading(true);
        get(GET_API_URLS.ALL_REYNOLDS_NUMBERS_PROPERTIES(airfoilId), expireCallback).then((res:AxiosResponse) => {
            if (res.status === 200) {
                const _reynoldsNumbersProperties = res.data.reynolds_numbers_properties; 
                setReynoldsNumbersProperties(_reynoldsNumbersProperties.map((property:any) => ({
                    id: property.id,    
                    reynoldsNumber: property.reynolds_number
                })));
            } else {
                enqueueSnackbar('Error while loading the reynolds numbers properties', {variant: 'error'});
            }
        }).catch((res:AxiosError) => {
            enqueueSnackbar('Error while loading the reynolds numbers properties', {variant: 'error'});
        }).finally(() => {
            setIsLoading(false);
        })
    }

    const expireCallback = () => {
        navigate('/login');
    }

    const onNameTextboxBlur = () => {
        if (name === ''){
            setNameError('Please enter a unique airfoil name');
        } else {
            setNameError('');
        }
    }

    const editAirfoil = () => {
        if (name === '') {
            enqueueSnackbar('Please enter a unique airfoil name', {variant: 'error'});
            return;
        } else if (interpolatedPoints.length === 0) {
            enqueueSnackbar('Please upload airfoil points text file', {variant: 'error'});
            return;
        }

        setIsLoading(true);

        const data = {
            'name': name,
            'points': originalPoints.map(point => ([point.x, point.y]))
        };

        put(PUT_API_URLS.AIRFOIL_UPDATE(airfoilId), data, expireCallback).then((res: AxiosResponse) => {
            if (res.status === 200) {
                enqueueSnackbar('Edited the airfoil successfully', {variant: 'success'});
            } else {
                enqueueSnackbar('Error while editing the airfoil', {variant: 'error'});
            }
        }).catch((err: AxiosError) => {
            enqueueSnackbar('Error while editing the airfoil', {variant: 'error'});
        }).finally(() => {
            setIsLoading(false);
        })
    }

    const deleteReynoldsNumber = (reynoldsNumberPropertiesId: number) => {
        setIsLoading(true);
        del(DELETE_API_URLS.REYNOLDS_NUMBER_PROPERTIES_DELETE(airfoilId, reynoldsNumberPropertiesId), expireCallback).then((res:AxiosResponse) => {
            if (res.status === 200) {
                enqueueSnackbar('Deleted the reynolds number successfully.', {variant: 'success'});
                const updatedReynoldsNumbersProperties = reynoldsNumbersProperties.filter(reynoldsNumber => reynoldsNumber.id !== reynoldsNumberPropertiesId)
                setReynoldsNumbersProperties(updatedReynoldsNumbersProperties)
            } else {
                enqueueSnackbar('Error while deleting the reynolds number.', {variant: 'error'});
            }
        }).catch((err) => {
            enqueueSnackbar(`${err.response?.data.unsuccessful_msg}`, {variant: 'error'});
        }).finally(() => {
            setIsLoading(false);
            setConfirmationProps(null);
        })
    };


    const onFileDrop = useCallback((acceptedFile: Array<File>) => {
        if (acceptedFile.length !== 1) {
            enqueueSnackbar('Please upload only one airfoil points text file', {variant:'error'});
            return;
        }

        const file = acceptedFile[0];
        const fileReader = new FileReader();
        fileReader.onload = (event) => {
            setIsLoading(true);

            if(event.target) {
                const fileText = event.target.result;
                if(fileText) {
                    let _originalPoints:Array<IPoints> = [];
                    try {
                        const lines = (fileText as string).split('\n');
                        _originalPoints = lines.map((line:string) => {
                            const points = line.trim().replace(/\s+/g, ' ').split(',');
                            if (points.length !== 2) {
                                throw Error(`Invalid airfoil point - ${line}`);
                            }
                            return ({'x': parseFloat(points[0]), 'y': parseFloat(points[1])});
                        });
                    } catch (error) {
                        enqueueSnackbar('Error while parsing the file', {variant:'error'});
                    }
                    setOriginalPoints(_originalPoints);

                    const data = {
                        'points': _originalPoints.map((point) => [point.x, point.y])
                    };
                    post(POST_API_URLS.AIRFOIL_INTERPOLATE_POINTS(), data, expireCallback).then((res: AxiosResponse) => {
                        if(res.status === 200){
                            const _interpolatedPoints = res.data.interpolated_points.map((point:[number,number]) => ({
                              'x': point[0],
                              'y': point[1]
                            }));
  
                            const _originalPoints = res.data.fixed_original_points.map((point:[number,number]) => ({
                              'x': point[0],
                              'y': point[1]
                            }));
  
                            setInterpolatedPoints(_interpolatedPoints);
                            setOriginalPoints(_originalPoints);
                          } else {
                          enqueueSnackbar('Error while interpolating airfoil points', {variant: 'error'});
                        }
                    }).catch((err: AxiosError) => {
                        if(err['code'] === 'ERR_BAD_REQUEST'){
                            enqueueSnackbar('Invalid airfoil points uploaded', {variant: 'error'});
                            setInterpolatedPoints([]);
                            setOriginalPoints([]);
                        } else{
                            enqueueSnackbar('Error while interpolating airfoil points', {variant: 'error'});
                        }
                    });
                }
            }

            setIsLoading(false);
        };

        if(file) {
            fileReader.readAsText(file);
        }
    }, []);

    const columns: GridColDef[] = [
        {field: 'reynoldsNumber', headerName: 'Reynolds Number', headerAlign: 'center', align: 'center', flex: 1, editable: false, type: 'number'},
        {field: 'edit', headerName: 'Edit', width: 60, align: 'left', headerAlign: 'left', sortable: false,
            renderCell: (params) => {
                return (
                    <IconButton 
                        onClick={() => {
                            setSelectedReynoldsNumber({id: params.row.id, reynoldsNumber: params.row.reynoldsNumber})
                            setIsReynoldsNumberPropertiesOpen(true);
                        }}
                    >
                        <EditOutlinedIcon/>
                    </IconButton>
                );
            }
        },
        {field: 'delete', align: 'left', headerAlign: 'left', headerName: 'Delete', flex: 1, sortable: false,
            renderCell: (params) => {
                const onDelete = (e:any) => {
                    e.stopPropagation();
                    setConfirmationProps({
                        title: 'Delete Reynolds Number',
                        body: 'Do you want to delete the reynolds number?',
                        onCancel: () => setConfirmationProps(null),
                        onConfirm: () => deleteReynoldsNumber(parseInt(params.row.id))
                    });
                } 
                return (
                    <IconButton onClick={onDelete}>
                        <DeleteForeverOutlinedIcon />
                    </IconButton>
                );
            }

        },
        {field: 'download', align: 'left', headerAlign: 'left', headerName: 'Download', flex: 1, sortable: false,
        renderCell: (params) => {
            return (
                <IconButton 
                    onClick={() => {
                        downloadAirfoilProperties(airfoilId, name, params.row.reynoldsNumber)
                    }}
                >
                    <SaveAltOutlinedIcon/>
                </IconButton>
            );
        }

        }
    ];

    const downloadAirfoilProperties = (airfoilId:number, airfoilName: string, reynoldsNumber: number) => { 
        setIsLoading(true);
        getBinary(GET_API_URLS.REYNOLDS_NUMBER_PROPERTIES_FILE(airfoilId, reynoldsNumber), expireCallback).then ((res: AxiosResponse) => {
            const blob = new Blob([res.data], {type: 'application/sla', endings: 'native'});
            const url = window.URL.createObjectURL(blob);

            let a = document.createElement("a");
            document.body.appendChild(a);
            a.href = url; 
            a.download = `${airfoilName.replaceAll(" ", "") + "_" + reynoldsNumber + "_Properties"}.csv`; 
            a.click();
             
            enqueueSnackbar('Downloaded the reynolds number properties file successfully.', {variant: 'success'});
        }).catch((res:AxiosError) => {
            enqueueSnackbar('Error while downloading the reynolds number properties file.', {variant: 'error'});
        }).finally(() => {
            setIsLoading(false);
        })
        
    }

    const Menubar = () => {
        return (
            <Stack spacing={2} style={{marginTop: '20px', marginBottom: '15px'}}>
                <MenuButton
                    Icon={SaveOutlinedIcon}
                    iconColor='black'
                    label='Save'
                    onClick={editAirfoil}
                    disabled={false}
                />
            </Stack>
        );
    }

    const options = {
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
            legend: {
                display: true,
            },
            title: {
                display: true,
                text: 'Airfoil Points',
            }
        },
        scales: {
            y: {
                min:-1,
                max:1
            },
            x: {
                min:-0.1,
                max:1.1
            }
        }
    };


    const sx = {m:1, width:'35ch'};

    return (
        <>
            {isLoading && <CircularSpinner/>}
            <NavigationBar showMenu={true}/>
            <Stack direction='row' alignItems='stretch' justifyContent='flex-start' style={{height: '100%'}}>
                <div style={{width:'50px'}}>
                    <Sidebar Menubar={Menubar} ActivePage={Page.AIRFOIL_DETAIL}/>
                </div>
                <Stack 
                    direction='column' 
                    alignItems='stretch' 
                    justifyContent='flex-start' 
                    spacing={3}
                    style={{height: '100%', marginLeft: '30px'}}
                >
                    <Typography style={{textAlign: 'left', fontSize:'24px', color: '#000000DE', paddingTop:'10px'}}>Airfoil Detail</Typography>
                    <Stack direction='row' spacing={6}>
                        <fieldset 
                            style={{
                                width: '1200px', border: '1px solid #6D77B0', borderRadius: '12px',
                                padding: '12px 6px 12px 6px',
                            }}
                        >
                            <legend style={{marginLeft: '17px'}}>Airfoil</legend>
                            <Stack direction='row' spacing={6}>
                                <Stack spacing={2} style={{width: '410px'}}>
                                    <TextField
                                        error={!!nameError}
                                        sx={sx}
                                        label='Airfoil Name'
                                        value={name}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            setName(e.target.value);
                                        }}
                                        size='small'
                                        helperText={nameError}
                                        onBlur={onNameTextboxBlur}
                                        style={{width:'403px'}}
                                    />
                                    <DataGrid
                                        columns={columns}
                                        rows={reynoldsNumbersProperties}
                                        pageSize={pageSize}
                                        onPageSizeChange={(newPageSize:number) => setPageSize(newPageSize)}
                                        initialState={{
                                            sorting: {
                                                sortModel: [{field:'reynoldsNumber', sort:'asc'}]
                                            }
                                        }}
                                        style={{marginLeft:'7px'}}
                                    />
                                    <Button 
                                        variant='contained' 
                                        onClick={() => {
                                            setSelectedReynoldsNumber(null);
                                            setIsReynoldsNumberPropertiesOpen(true);
                                        }}
                                        style={{height: '45px', marginLeft: '7px'}}
                                    >
                                        Add New Reynolds Number
                                    </Button>
                                </Stack>
                                <Stack spacing={2} style={{width: '1000px'}}>
                                    <div style={{paddingLeft: '9px', paddingRight: '9px'}}>
                                        <Dropzone onDrop={onFileDrop} accept={{'text/plain': ['.txt']}}/>
                                    </div>
                                    {(interpolatedPoints.length > 0) && (
                                        <Scatter
                                            options={options}
                                            data={{datasets: [
                                                {
                                                label: 'Original points',
                                                data: originalPoints,
                                                backgroundColor: 'rgba(255, 0, 0, 1)',
                                                showLine: true
                                                },
                                                {
                                                    label: 'Interpolated points',
                                                    data: interpolatedPoints,
                                                    backgroundColor: 'rgba(0, 0, 255, 1)',
                                                    showLine: true
                                                },
                                            ]}}
                                        />
                                    )}
                                </Stack>
                            </Stack>
                        </fieldset>
                    </Stack>
                </Stack>
            </Stack>
            <Dialog
                open={isReynoldsNumberPropertiesOpen}
                onClose={() => setIsReynoldsNumberPropertiesOpen(false)}
                PaperProps={{
                    style: {
                      backgroundColor: 'transparent',
                      boxShadow: 'none',
                      maxWidth: '1000px'
                    }
                }}
            >
                <DialogContent style={{backgroundColor:'transparent'}}>
                    <ReynoldsNumberProperties
                        airfoilId={airfoilId}
                        onComplete={() => {
                            setIsReynoldsNumberPropertiesOpen(false);
                            loadReynoldsNumbersProperties();
                        }}
                        reynoldsNumberDetail={selectedReynoldsNumber}
                    />
                </DialogContent>
            </Dialog>
            {confirmationProps && <ConfirmationDialog {...confirmationProps}/>}
        </>
    );
};

export default WithAuthenticate(AirfoilDetail);

