import React, {useState, useCallback, useEffect} from 'react';
import {  FormControl, FormControlLabel, FormLabel, Input, InputLabel, MenuItem, Radio, RadioGroup, Select, SelectChangeEvent, Grid, TextField, Tooltip, Typography } from '@mui/material';
import { Stack, Button, IconButton } from '@mui/material';
import { Close as IconClose } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router-dom';
import { AxiosError, AxiosResponse } from 'axios';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import SaveAltOutlinedIcon from '@mui/icons-material/SaveAltOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import * as _ from 'lodash';

import Dropzone from 'common/Dropzone';
import CircularSpinner from "common/CircularSpinner";
import ConfirmationDialog, { ConfirmationProps } from 'common/ConfirmationDialog';
import {get, postFile, del, getBinary } from "utils/api";
import { DELETE_API_URLS, GET_API_URLS, POST_API_URLS } from "constants/apiUrls";
import { upload } from '@testing-library/user-event/dist/upload';

interface ResultFilesProps {
    experimentTitle: string,
}

export const ResultFiles = (props: ResultFilesProps) => {
    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();
    const params = useParams();
    const experimentId = parseInt(params['experimentId'] || '0');

    const {experimentTitle} = props;

    const [files, setFiles] = useState<any>([]);
    const [uploadCandidateFile, setUploadCandidateFile] = useState<File|null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [confirmationProps, setConfirmationProps] = useState<ConfirmationProps | null>(null); 
    const [fileType, setFileType] = useState<string>('OTHER');
    const date = new Date().toLocaleString('sv').replace(' ','T'); 
    const formattedDate = date.split(':')[0] + ':' + date.split(':')[1]
    const [experimentDateTime, setExperimentDateTime] = useState<string>(formattedDate);
    const [description, setDescription] = useState<string>('');

    const expireCallback = () => {
        navigate('/login');
    }

    useEffect(() => {
        loadFiles();
    }, []);

    const loadFiles = () => {
        setIsLoading(true);
        get(GET_API_URLS.RESULT_FILES(experimentId), expireCallback).then((res: AxiosResponse) => {
            if (res.status === 200) {
                setFiles(res.data.result_files);
            } else {
                enqueueSnackbar('Error while loading result files.', {variant: 'error'});
            }
        }).catch((err:AxiosError) => {
            enqueueSnackbar('Error while loading result files.', {variant: 'error'});
        }).finally(() => {
            setIsLoading(false);
        })
    };

    const onDrop = useCallback((acceptedFile: any) => {
        if (acceptedFile.length === 0) {
            enqueueSnackbar("You can upload only one file at a time", {variant: 'error'})
            return;
        }

        const file = acceptedFile[0];
        const name = acceptedFile[0].name; 
        const mime = acceptedFile[0].type;
        const newFile = new FormData();
        newFile.append('filename', name);
        newFile.append('mime', mime);
        newFile.append('file_data', file);
        setUploadCandidateFile(file);
    }, []);

    const uploadFile = () => {
        if (!uploadCandidateFile) {
            enqueueSnackbar('No file has been uploaded', {variant: 'error'});
            return
        }

        const data = new FormData();
        data.append('filename', uploadCandidateFile.name);
        data.append('mime', uploadCandidateFile.type);
        data.append('file_data', uploadCandidateFile);
        data.append('fileType', fileType);
        data.append('experimentDate', experimentDateTime);
        data.append('description', description);
        if (!data.get('filename')){
            enqueueSnackbar('Missing filename', {variant: 'error'});
            return;
        }
        if (!data.get('experimentDate')){
            enqueueSnackbar('Please enter the experiment date', {variant: 'error'});
            return;
        }

        if((fileType === 'TEST_STAND_RPM_SWEEP') && !['text/csv', 'text/plain', 'application/vnd.ms-excel'].includes(uploadCandidateFile.type)){
            enqueueSnackbar('Invalid file type for RPM Sweep.', {variant: 'error'});
            return;
        }
        setIsLoading(true);
        postFile(POST_API_URLS.RESULT_FILE_UPLOAD(experimentId), data, expireCallback).then((res:AxiosResponse) => {
            if (res.status === 200) {
                loadFiles();
                setUploadCandidateFile(null);
                setFileType('OTHER');
                enqueueSnackbar('Uploaded the file successfully.', {variant: 'success'});
            } else {
                enqueueSnackbar('Error while uploading the file.', {variant: 'error'});
            }
        }).catch((res:AxiosError) => {
            enqueueSnackbar('Error while uploading the file.', {variant: 'error'});
        }).finally(() => {
            setIsLoading(false);
        })
    }

    const onFileDownload = (fileId: number, filename: string, mime: string) => {
        setIsLoading(true);
        getBinary(GET_API_URLS.RESULT_FILE_EXPORT(experimentId, fileId)).then((res: AxiosResponse) => {
            const blob = new Blob([res.data], {type: mime, endings: 'native'});
            const url = window.URL.createObjectURL(blob);

            let a = document.createElement("a");
            document.body.appendChild(a);
            a.href = url;
            a.download = `${experimentTitle}_${filename}`;
            a.click();
             
            enqueueSnackbar('Downloaded the file successfully.', {variant: 'success'});
        }).catch((res:AxiosError) => {
            enqueueSnackbar('Error while downloading the file.', {variant: 'error'});
        }).finally(() => {
            setIsLoading(false);
        })
    }

    const deleteFile = (fileId: number) => {
        setIsLoading(true);
        del(DELETE_API_URLS.RESULT_FILE(experimentId, fileId), expireCallback).then((res:AxiosResponse) => {
            if (res.status === 200) {
                enqueueSnackbar('Deleted the file successfully.', {variant: 'success'});
            } else {
                enqueueSnackbar('Error while deleting the file.', {variant: 'error'});
            }
        }).catch((res:AxiosError) => {
            enqueueSnackbar('Error while deleting the file.', {variant: 'error'});
        }).finally(() => {
            setIsLoading(false);
            setConfirmationProps(null);
            loadFiles();
        })
    }

    const columns: GridColDef[] = [
        {field: 'id', headerName: 'ID', headerAlign: 'center', align: 'center', width: 60, editable: false, type: 'number'},
        {field: 'filename', headerName: 'Filename', headerAlign: 'center', align: 'center', flex: 1, editable: false, type: 'string'},
        {field: 'filetype', headerName: 'Filetype', headerAlign: 'center', align: 'center', width: 120, editable: false, type: 'string'},
        {field: 'download', headerName: 'Download', headerAlign: 'center', align: 'center', width: 90, sortable: false,
            renderCell: (params) => {
                return (
                    <IconButton 
                        onClick={() => {
                            onFileDownload(params.row.id, params.row.filename, params.row.mime)}
                        }
                    >
                        <SaveAltOutlinedIcon/>
                    </IconButton>
                );
            }
        },
        {field: 'delete', headerName: 'Delete', headerAlign: 'center', align: 'center', width: 90, sortable: false,
            renderCell: (params) => {
                return (
                    <IconButton 
                        onClick={() => {
                            setConfirmationProps({
                                title: 'Delete File',
                                body: 'Do you want to delete the file?',
                                onCancel: () => setConfirmationProps(null),
                                onConfirm: () => deleteFile(params.row.id)
                            })
                        }}
                    >
                        <DeleteForeverOutlinedIcon/>
                    </IconButton>
                );
            }
        }
    ];

    return (
        <>
            {
                isLoading && <CircularSpinner />
            }
            <div 
                style={{width: '650px', border: '1px solid #707070', boxShadow:'0px 3px 6px #00000029', 
                borderRadius: '20px', backgroundColor: 'white', color: 'black', padding: '10px', overflow: 'visible'}}            
            >
                <Stack spacing={2}>
                    <Dropzone 
                        onDrop={onDrop} 
                        accept={{}}
                        description="Drag 'n' drop the file here, or click to select the file. If the file already exists, then it'll be overwritten by the new file."
                    />
                    {
                        uploadCandidateFile && (
                            <Stack direction='row'>
                                <div style={{display:'flex', alignItems:'center'}}>{uploadCandidateFile.name}</div>
                                <IconButton 
                                    onClick={() => setUploadCandidateFile(null)} 
                                    style={{width:'30px', height:'30px', marginLeft:'20px'}}
                                >
                                    <IconClose style={{color:'black'}} />
                                </IconButton>
                            </Stack>
                        )
                    }
                    <Stack spacing={2} direction='row'>
                        <FormControl sx={{m:1, width: '22ch'}} variant="outlined" style={{marginTop:'20px'}}>
                            <InputLabel
                                id='result-file-type-label'
                                style={{fontSize:'17px'}}
                            >
                                File Type
                            </InputLabel>
                            <Select
                                id='result-file-type'
                                labelId='result-file-type-label'
                                label='File Type'
                                value={fileType}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    setFileType(value);
                                }}
                                size='small'
                                style={{height:'43px'}}
                            >
                                <MenuItem value='OTHER'>Other</MenuItem>
                                <MenuItem value='TEST_STAND_RPM_SWEEP'>RPM Sweep</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl sx={{m:1, width: '22ch'}} variant="outlined" style={{marginTop:'20px'}}>
                            <Input  
                                name='dateTime-input'
                                type='datetime-local'
                                value={experimentDateTime}
                                onChange={(e) => {
                                    setExperimentDateTime(e.target.value);
                                }}
                                style={{fontSize:'17px',width:'250px',height:'33px'}}
                            />
                        </FormControl>
                    </Stack>
                    <TextField
                        label="Description"
                        multiline
                        rows={2}
                        value={description}
                        onChange={(e) => {
                            setDescription(e.target.value);
                        }}
                        autoComplete='off'
                    />
                    <Button
                        variant='contained'
                        onClick={() => uploadFile()}
                        style={{marginTop: '30px'}}
                    >
                        SAVE
                    </Button>
                    <DataGrid
                        style={{overflowY:'scroll', overflowX:'visible', marginTop:'45px'}}
                        autoHeight
                        columns={columns}
                        rows={files}
                        checkboxSelection
                        sx={{
                            "& .MuiDataGrid-cell": {
                            padding: '2px 10px'
                            }
                        }}
                    />
                </Stack>            
            </div>
            {confirmationProps && <ConfirmationDialog {...confirmationProps}/>}
        </>
    );
}

export default ResultFiles;