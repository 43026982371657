import React, {useEffect, useState} from 'react';
import { Grid, Typography, Stack, Tooltip, IconButton } from '@mui/material';
import { DataGrid, GridColDef, GridRowId, GridSelectionModel } from '@mui/x-data-grid';
import { AxiosError, AxiosResponse } from 'axios';
import { useSnackbar} from 'notistack';
import { createSearchParams, useNavigate, useParams } from "react-router-dom";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SaveAltOutlinedIcon from '@mui/icons-material/SaveAltOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import * as _ from 'lodash';

import { ExperimentStatus as ExperimentStatusEnum, Page } from 'constants/enums';
import CircularSpinner from 'common/CircularSpinner';
import { DELETE_API_URLS, GET_API_URLS, POST_API_URLS } from 'constants/apiUrls';
import {get, del, postBinary} from 'utils/api';
import NavigationBar from 'common/NavigationBar';
import Sidebar, {MenuButton} from 'common/Sidebar';
import ConfirmationDialog, { ConfirmationProps } from 'common/ConfirmationDialog';
import WithAuthenticate from 'common/hoc/WithAuthenticate';
import { ExperimentStatus } from 'components/Dashboard/ExperimentStatus';
import { CompressOutlined } from '@mui/icons-material';
import CompareOutlinedIcon from '@mui/icons-material/CompareOutlined';
import { useLocation } from "react-router-dom";
import { Link } from 'react-router-dom'


export const CompareResults = () => {
    const {enqueueSnackbar} = useSnackbar();
    const navigate = useNavigate();
    const params = useParams();
    const autoSelectedExperiment = [parseInt(params['experimentId'] || '')]

    const [experiments, setExperiments] = useState<any>([]);
    const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isTableDisabled, setIsTableDisabled] = useState<boolean>(false);
    const [confirmationProps, setConfirmationProps] = useState<ConfirmationProps | null>(null); 
    const [pageSize, setPageSize] = useState<number>(25);

    const expireCallback = () => {
        navigate('/login');
    }

    const goToDetailComparison = () => {

        if (selectionModel.length < 2) {
            enqueueSnackbar('At least 2 experiments should be selected', {variant: 'warning'});
        } else {

            navigate("/compare/results/detail", {
                state: {
                    experiment_details: experiments.filter((_item: { id: GridRowId; }) => selectionModel.includes(_item.id))
                }
              });
        }
    }

    useEffect(() => {
        loadExperiments();    
    }, []);

    const loadExperiments = () => {
        setIsLoading(true);
        get(GET_API_URLS.EXPERIMENTS_COMPLETED(), expireCallback).then((res:AxiosResponse) => {
            if (res.status === 200) {
                setExperiments(res.data.experiments);
            } else {
                enqueueSnackbar('Error while loading experiments.', {variant: 'error'});
            }
        }).catch((err:AxiosError) => {
            enqueueSnackbar('Error while loading experiments.', {variant: 'error'});
        }).finally(() => {
            setIsLoading(false);
        })
    }
    
    const columns: GridColDef[] = [
        {field: 'id', headerName: 'ID', width:60, align: 'left', headerAlign: 'left', editable: false, type: 'number'},
        {field: 'title', headerName: 'Project', width:150, editable: false, type: 'string'},
        {field: 'created_on', headerName: 'Date', width: 100, align: 'left', headerAlign: 'left', editable: false, type: 'date', renderCell: (params) => {
            const date = params.row.created_on;
            const formattedDate = new Date(date).toLocaleDateString('en-US');
            return ( 
                <div>{formattedDate}</div>
            );
        }},
        {field: 'hub_break_cl_lower', headerName: 'Hub Break', width: 100, align: 'left', headerAlign: 'left', editable: false, type: 'number', 
            renderCell: (params) => {
                return (
                    <div>{params.row.cl_design === 'CONSTANT'?'N/A':Number(params.row.hub_break_cl_lower)}</div>
                );
        }},
        {field: 'tip_break_cl_lower', headerName: 'Tip Break', width: 100, align: 'left', headerAlign: 'left', editable: false, type: 'number', 
            renderCell: (params) => {
                return (
                    <div>{params.row.cl_design === 'CONSTANT'?'N/A':Number(params.row.tip_break_cl_lower)}</div>
                );
        }},
        {field: 'tip_break_radius', headerName: 'Diameter', width: 100, align: 'left', headerAlign: 'left', editable: false, type: 'number', 
            renderCell: (params) => {
                return (
                    <div>{params.row.cl_design === 'CONSTANT'?'N/A':Number(params.row.tip_break_radius * 2)}</div>
                );
        }},
        {field: 'airfoil_name', headerName: 'Airfoil', width: 200, align: 'left', headerAlign: 'left', editable: false, type: 'number', 
            renderCell: (params) => {
                return (
                    <div>{params.row.airfoil_name}</div>
                );
        }},
        {field: 'status', headerName: 'Status', editable: false, 
        type: 'singleSelect', valueOptions: ['a', 'b'], width:340, renderCell: (params) => {
            return (
                <ExperimentStatus 
                    experimentId={parseInt(params.row.id)} 
                    status={ExperimentStatusEnum[params.row.status as ExperimentStatusEnum]}
                />
            );
        }}
      ];

    const Menubar = () => {
        return (
            <Stack spacing={2} style={{marginTop:'30px', marginBottom: '33px'}}>
                <MenuButton 
                    Icon={CompareOutlinedIcon}
                    iconColor='black'
                    label='Compare'
                    onClick={goToDetailComparison}
                    disabled={false}
                />
            </Stack>
        );
    } 

    return (
        <>
            {isLoading && <CircularSpinner/>}
            <NavigationBar showMenu={true}/>
            <Stack direction='row' alignItems='stretch' justifyContent='flex-start' style={{width:'1300px'}}>
                <div style={{width:'50px'}}>
                    <Sidebar Menubar={Menubar} ActivePage={Page.DASHBOARD}/>
                </div>
                <Stack spacing={3} style={{paddingLeft: '30px', width: '100%'}}>
                    <Typography 
                        style={{textAlign: 'left', fontSize:'24px', color: '#000000DE', paddingTop:'10px'}}
                    >
                        Compare Results (Select Max 3)
                    </Typography>
                    <DataGrid
                        autoHeight
                        columns={columns}
                        rows={experiments}
                        checkboxSelection
                        selectionModel={_.compact(_.union(selectionModel,autoSelectedExperiment))} 
                        onSelectionModelChange={(newSelectionModel) => {
                            newSelectionModel.length > 3 ? enqueueSnackbar('Maximum 3 experiments can be selected', {variant: 'warning'}) :  setSelectionModel(_.compact(_.union(newSelectionModel,autoSelectedExperiment)));                          
                        }}
                        pageSize={pageSize}
                        onPageSizeChange={(newPageSize:number) => setPageSize(newPageSize)}
                        sx={{
                            "& .MuiDataGrid-cell": {
                            padding: '2px 10px'
                            }
                        }}
                        //isRowSelectable={}
                    />
                </Stack>
            </Stack>
            {confirmationProps && <ConfirmationDialog {...confirmationProps}/>}
        </>
    );
}

export default WithAuthenticate(CompareResults);