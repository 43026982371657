import * as _ from 'lodash';


export const getExperimentTitle = (experiment:any) => {
    if (!experiment) {
        return 'Experiment';
    } else if (experiment['cl_design'] === 'CONSTANT') {
        return `${experiment['company']}_${experiment['platform']}_${experiment['airfoil_name']}_CONSTANT_${experiment['id']}`;
    } else {
        let hubBreak = Number(experiment['hub_break_cl_lower']).toFixed(2);
        if (experiment['hub_break_cl_lower'] !== experiment['hub_break_cl_upper']) {
            hubBreak = `${Number(experiment['hub_break_cl_lower']).toFixed(2)}-${Number(experiment['hub_break_cl_upper']).toFixed(2)}`;
        }

        let tipBreak = Number(experiment['tip_break_cl_lower']).toFixed(2);
        if (experiment['tip_break_cl_lower'] !== experiment['tip_break_cl_upper']) {
            tipBreak = `${Number(experiment['tip_break_cl_lower']).toFixed(2)}-${Number(experiment['tip_break_cl_upper']).toFixed(2)}`;
        }

        return `${experiment['company']}_${experiment['title']?experiment['title']:experiment['platform']}_${experiment['airfoil_name']}_${hubBreak}_${tipBreak}_${experiment['id']}`;
    }
}


export const formatNumberWithCommas = (num:number|string) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");


export const dataURLToBlob = (dataUrl: string) => {
    const arr = dataUrl.split(',');
    const matches = arr[0].match(/:(.*?);/);
    const mime = matches?matches[1]:'0';
    const byteString = window.atob(arr[arr.length - 1]);
    let n = byteString.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = byteString.charCodeAt(n)
    }

    return new Blob([u8arr], {type: mime});
}


export const blobToDataURL = (blob: Blob, callback: (dataURL:string) => void) => {
    const fileReader = new FileReader();
    fileReader.onload = (e) => {
        callback(e.target?.result as string);
    }

    fileReader.readAsDataURL(blob);
}


export const getEnumKeys = <T extends string, TEnumValue extends string | number>(enumVariable: {[key in T]: TEnumValue}) => {
    return Object.keys(enumVariable) as Array<T>;
}


export const isNumeric = (number:string) => !_.isNaN(Number(number));